<template>
  <div>
    <div style="display: flex; align-items: center; justify-content: space-between">
      <div>
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/product' }"
            >我的产品</el-breadcrumb-item
          >
        </el-breadcrumb> -->
        <div class="dis align_center">
          <div class="icon"></div>
          <div class="service">我的产品</div>
        </div>
      </div>
      <div @click="addproduct()" style="
          width: 122px;
          height: 40px;
          background: #ff6618;
          border-radius: 6px;
          line-height: 40px;
          text-align: center;
        " class="fff">
        添加产品
      </div>
      <!-- <div
        @click="postcurrency()"
        style="
          width: 122px;
          height: 40px;
          background: #ff6618;
          border-radius: 6px;
          line-height: 40px;
          text-align: center;
        "
        class="fff"
      >
        添加产品
      </div> -->
    </div>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="待审核" name="first">
        <el-table :data="TableData" style="width: 100%">
          <el-table-column prop="ID" label="ID" width="100" align="center"></el-table-column>
          <el-table-column prop="productname" label="产品名称" min-width="125" align="center">
          </el-table-column>
          <el-table-column align="center" prop="productype" label="产品类型" width="160"></el-table-column>
          <el-table-column prop="pattern" label="服务模式" width="160" align="center">
          </el-table-column>
          <el-table-column prop="spend" label="费用" min-width="125" align="center">
            <template slot-scope="props">
              <div v-if="props.row.spend">{{ props.row.spend }} </div>
              <div v-else>无</div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="联系人" width="160" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="联系方式" width="160" align="center">
          </el-table-column>
          <el-table-column prop="Email" label="邮箱" width="180" align="center">
          </el-table-column>
          <el-table-column prop="content" label="产品详情" min-width="125" align="center">
            <!-- <template slot-scope="props">
              <el-button type="text" size="small" style="color: #c065e7" @click="lookproduct">查看产品详情</el-button>
            </template> -->
            <template slot-scope="props">
              <el-button type="text" size="small" @click="look(props.row.ID)" style="color: #c065e7">查看产品详情</el-button>
            </template>
          </el-table-column>

          <el-table-column prop="content" label="操作" min-width="125" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" style="color: #fb1010" @click="cancel(scope.row)">取消审核</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane label="已通过" name="second">
        <el-table :data="TableData" style="width: 100%">
          <el-table-column prop="ID" label="ID" width="100" align="center"></el-table-column>
          <el-table-column align="center" prop="productname" label="产品名称" min-width="125">
          </el-table-column>
          <el-table-column align="center" prop="productype" label="产品类型" width="160"></el-table-column>
          <el-table-column prop="pattern" label="服务模式" width="160" align="center">
          </el-table-column>
          <el-table-column prop="spend" label="费用" min-width="125" align="center">
            <template slot-scope="props">
              <div v-if="props.row.spend">{{ props.row.spend }} </div>
              <div v-else>无</div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="联系人" width="160" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="联系方式" width="160" align="center">
          </el-table-column>
          <el-table-column prop="Email" label="邮箱" width="180" align="center">
          </el-table-column>
          <el-table-column prop="content" label="产品详情" min-width="125" align="center">
            <template slot-scope="props">
              <el-button type="text" size="small" @click="look(props.row.ID)" style="color: #c065e7">查看产品详情</el-button>
            </template>
          </el-table-column>
          <el-table-column label="是否展示" min-width="125" align="center">
            <template slot-scope="props">
              <el-switch :value="props.row.is_shelf == 1 ? true : false" active-color="#13ce66" inactive-color="#ff4949"
                @change="show(props.row)">
              </el-switch>
            </template>

          </el-table-column>
          <el-table-column prop="content" label="操作" min-width="125" align="center">
            <template slot-scope="props">
              <el-button type="text" size="small" @click="amend(props.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>

      </el-tab-pane>
      <el-tab-pane label="未通过" name="third">
        <el-table :data="TableData" style="width: 100%">
          <el-table-column prop="ID" label="ID" width="100" align="center"></el-table-column>
          <el-table-column prop="productname" label="产品名称" min-width="125" align="center">
          </el-table-column>
          <el-table-column align="center" prop="productype" label="产品类型" width="160"></el-table-column>
          <el-table-column prop="pattern" label="服务模式" width="160" align="center">
          </el-table-column>
          <el-table-column prop="spend" label="费用" min-width="125" align="center">
            <template slot-scope="props">
              <div v-if="props.row.spend">{{ props.row.spend }} </div>
              <div v-else>无</div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="联系人" width="160" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="联系方式" width="160" align="center">
          </el-table-column>
          <el-table-column prop="Email" label="邮箱" width="180" align="center">
          </el-table-column>
          <el-table-column prop="content" label="产品详情" min-width="125" align="center">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row)" type="text" size="small"
                style="color: #c065e7">查看产品详情</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="content" label="操作" min-width="125" align="center">
            <template>
              <el-button type="text" size="small">重新提交</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <!-- <el-tab-pane label="添加产品" name="add">添加产品</el-tab-pane> -->
    </el-tabs>
    <!-- <app-mypaginations
      background
      :pagSize="paginations"
      :allData="tableData"
      @setHandleCurrent="handleCurrent"
      @setHandleSize="handleSize"
      @setPage="setPage"
      @setSize="setSize"
    /> -->

    <el-pagination v-if="TableData.length != 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="paginations.page_index" :page-sizes="paginations.page_sizes" :page-size="paginations.page_size"
      :layout="paginations.layout" :total="paginations.total">
    </el-pagination>
  </div>
</template>


<script>
export default {
  data() {
    return {
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
      activeName: "first",
      value: true,
      tabPosition: "",
      dialogVisible: false,
      sta: 1,
      // listsItem: [],
      page: 1,
      TableData: [],
      allTableData: [],
      num: "",
      total: ""
    };
  },
  created() {
    this.productLst();
    this.Info();
  },
  methods: {
    show(item) {
      console.log(item)
      let data = {
        token: localStorage.eleToken,
        id: item.ID,
        is_shelf: item.is_shelf == 1 ? '2' : '1'
      };

      this.$post("/service_product_status", data).then((res) => {
        console.log(res)
        this.productLst()
      });
    },
    Info() {
      this.teate = false;
      let data = {
        token: localStorage.eleToken,
      };

      this.$post("/mechanism_info", data).then((res) => {
        this.num = res.result.audit_status;
      });
    },

    // 认证
    authentication() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$get("/enterprise_user_status", data).then((res) => {
        console.log(res, "res");
        if (res.status === 1) {
          this.addproduct();
        } else if (res.status === 2) {
          // // location.href = "http://sso.zhenchangkj.com:9000/realname/index";
          // location.href = "http://sso.smeqh.cn:9000/realname/index";

          this.$message({
            type: "info",
            message: "您的账号还未实名认领，请认领通过之后再进行下一步操作",
          });
        }
      });
    },

    postcurrency() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$post("mechanism_currency", data).then((res) => {
        console.log(res, "res");
        if (res.result.audit_status === 2) {
          this.addproduct();
        } else if (
          res.result.audit_status === 0 ||
          res.result.audit_status === 3
        ) {
          this.authentication();
        } else if (res.result.audit_status === 1) {
          this.$message({
            type: "info",
            message: "审核中",
          });
        }
      });
    },

    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
    //查看
    look(item) {
      console.log(item, "item");
      this.$router.push({ path: "/serveDetails", query: { productid: item } })
    },
    //取消
    cancel(item) {
      console.log(item.ID);
      // service_product_del  post
      this.$confirm("是否取消审核", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(this.TableData, "this.TableData");
          let data = {
            token: localStorage.eleToken,
            id: item.ID,
          };

          this.$post("/service_product_del ", data).then((res) => {
            console.log(res, "res");
            this.productLst();
          });

          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //添加产品
    addproduct() {
      this.$router.push("/Addproduct");
    },
    amend(item) {
      console.log(item, 'item')
      this.$router.push({
        path: "/Addproduct",
        query: { cid: item.ID }
      });

    },
    // 显示条数发生改变时的回调
    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;

      this.TableData = this.allTableData.filter((item, index) => {
        return index < page_size;
      });
    },
    // 分页

    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allTableData[i]) {
          tables.push(this.allTableData[i]);
        }
        this.TableData = tables;
      }
    },


    //切换触发
    handleClick(tab, event) {
      console.log(tab.index, "tab.index");
      this.sta = Number(tab.index) + 1;
      this.productLst();
    },
    //产品列表
    productLst() {
      let data = {
        token: localStorage.eleToken,
        status: this.sta,
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
      };

      this.$post("/service_product_lst", data).then((res) => {
        this.allTableData = res.result;
        this.total = res.count;

        console.log(this.allTableData, "this.allTableData789");

        if (res.count === "") {
          this.paginations.total = 0;
        } else {
          this.paginations.total = res.count;
        }
        //设置默认的分页数据
        if (this.allTableData.length != 0) {
          this.TableData = this.allTableData.filter((item, index) => {
            return index < this.paginations.page_size;
          });
        } else {
          this.TableData = [];
        }

      });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-pagination {
  text-align: center;
  margin-top: 20px;
}

.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}

.service {
  height: 30px;
  font-size: 24px;
  font-family: Source Han Sans SC Medium, Source Han Sans SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 30px;
  margin-left: 10px;
}

//修改菜单颜色
/deep/.el-tabs__item.is-active {
  color: #ff7129 !important;
}

/deep/.el-tabs__active-bar {
  background-color: #ff7129 !important;
}

/deep/.el-tabs__item:hover {
  color: #ff7129 !important;
}
</style>
